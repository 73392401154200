var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dots-sheet-figure-wrapper",class:{ 'is-disab': _vm.isDisabled, 'is-template': _vm.isTemplate }},[_c('svg',{attrs:{"viewBox":"0 0 480 320"}},[_c('rect',{attrs:{"x":"0.5","y":"0.5","width":"479","height":"319","fill":_vm.isTemplate ? '#FAFAFA' : _vm.isDisabled ? '#ddd' : 'white',"stroke":"black"}}),_c('rect',{attrs:{"x":"0.5","y":"280.5","width":"479","height":"39","fill":"transparent","stroke":"black"}}),_vm._l((_vm.myDesign.labels),function(tt){return _c('text',{key:tt.text,staticStyle:{"font":"18px sans-serif","white-space":"pre-line","text-anchor":"middle"},attrs:{"x":tt.x,"y":tt.y}},[_vm._v(" "+_vm._s(tt.text)+" ")])}),_vm._l((_vm.product.design.addTraces),function(manuDot){return _c('g',{key:("manudot-" + (manuDot.id))},[(manuDot.shape === 'circle')?_c('circle',{attrs:{"cx":manuDot.cx,"cy":manuDot.cy,"r":_vm.myDesign.dotRadius,"stroke":"none","fill":_vm.leanParams.availColors[manuDot.color].colorHex}}):_vm._e(),(manuDot.shape === 'square')?_c('rect',{attrs:{"x":manuDot.cx - _vm.myDesign.dotRadius,"y":manuDot.cy - _vm.myDesign.dotRadius,"width":_vm.myDesign.dotRadius * 2,"height":_vm.myDesign.dotRadius * 2,"stroke":"none","fill":_vm.leanParams.availColors[manuDot.color].colorHex}}):_vm._e()])}),_vm._l((_vm.myDesign.dots),function(dot){
var _obj;
return _c('g',{key:("product-" + (_vm.product.id) + "-dot-" + (dot.id)),ref:("product-" + (_vm.product.id) + "-dot-" + (dot.id)),refInFor:true,class:( _obj = {}, _obj['is-success'] = dot.status === 'Successful', _obj['is-failed'] = dot.status === 'Failed', _obj ),attrs:{"id":("product-" + (_vm.product.id) + "-dot-" + (dot.id))}},[(dot.shape === 'square')?_c('rect',{attrs:{"x":dot.cx - _vm.myDesign.dotRadius,"y":dot.cy - _vm.myDesign.dotRadius,"width":_vm.myDesign.dotRadius * 2,"height":_vm.myDesign.dotRadius * 2,"stroke-width":_vm.isTemplate || (dot.status && (_vm.showGuides || !_vm.product.hideStatus))
            ? 3
            : 1,"stroke":_vm.isTemplate && !dot.status
            ? '#000'
            : !dot.status || (!_vm.showGuides && _vm.product.hideStatus)
            ? '#aaa'
            : dot.status === 'Successful'
            ? 'green'
            : 'red',"fill":_vm.showFinished
            ? _vm.leanParams.availColors[dot.color].colorHex
            : 'rgba(0,0,0,0)'},on:{"click":function($event){return _vm.clickDot(dot, $event)}}}):_vm._e(),(dot.shape === 'circle')?_c('circle',{attrs:{"cx":dot.cx,"cy":dot.cy,"r":_vm.myDesign.dotRadius,"stroke-width":_vm.isTemplate || (dot.status && (_vm.showGuides || !_vm.product.hideStatus))
            ? 3
            : 1,"stroke":_vm.isTemplate && !dot.status
            ? '#000'
            : !dot.status || (!_vm.showGuides && _vm.product.hideStatus)
            ? '#aaa'
            : dot.status === 'Successful'
            ? 'green'
            : 'red',"fill":_vm.showFinished
            ? _vm.leanParams.availColors[dot.color].colorHex
            : 'rgba(0,0,0,0)'},on:{"click":function($event){return _vm.clickDot(dot, $event)}}}):_vm._e(),((!_vm.product.hideStatus && dot.status) || _vm.showGuides)?_c('line',{attrs:{"x1":dot.cx,"y1":dot.cy - _vm.product.design.targetRadius / 2,"x2":dot.cx,"y2":dot.cy + _vm.product.design.targetRadius / 2,"stroke":"#888"}}):_vm._e(),((!_vm.product.hideStatus && dot.status) || _vm.showGuides)?_c('line',{attrs:{"x1":dot.cx - _vm.product.design.targetRadius / 2,"y1":dot.cy,"x2":dot.cx + _vm.product.design.targetRadius / 2,"y2":dot.cy,"stroke":"#888"}}):_vm._e(),((!_vm.product.hideStatus && dot.status) || _vm.showGuides)?_c('circle',{attrs:{"cx":dot.cx,"cy":dot.cy,"r":_vm.product.design.targetRadius,"stroke":"#888","fill":"rgba(0,0,0,0)"},on:{"click":function($event){return _vm.clickDot(dot, $event)}}}):_vm._e()])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }