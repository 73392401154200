<template>
  <div
    class="dots-sheet-figure-wrapper"
    :class="{ 'is-disab': isDisabled, 'is-template': isTemplate }"
  >
    <svg viewBox="0 0 480 320">
      <rect
        x="0.5"
        y="0.5"
        width="479"
        height="319"
        :fill="isTemplate ? '#FAFAFA' : isDisabled ? '#ddd' : 'white'"
        stroke="black"
      />
      <rect
        x="0.5"
        y="280.5"
        width="479"
        height="39"
        fill="transparent"
        stroke="black"
      />
      <text
        v-for="tt in myDesign.labels"
        :key="tt.text"
        :x="tt.x"
        :y="tt.y"
        style="font: 18px sans-serif; white-space: pre-line; text-anchor: middle;"
      >
        {{ tt.text }}
      </text>
      <g
        v-for="manuDot in product.design.addTraces"
        :key="`manudot-${manuDot.id}`"
      >
        <circle
          v-if="manuDot.shape === 'circle'"
          :cx="manuDot.cx"
          :cy="manuDot.cy"
          :r="myDesign.dotRadius"
          stroke="none"
          :fill="leanParams.availColors[manuDot.color].colorHex"
        />
        <rect
          v-if="manuDot.shape === 'square'"
          :x="manuDot.cx - myDesign.dotRadius"
          :y="manuDot.cy - myDesign.dotRadius"
          :width="myDesign.dotRadius * 2"
          :height="myDesign.dotRadius * 2"
          stroke="none"
          :fill="leanParams.availColors[manuDot.color].colorHex"
        />
      </g>

      <g
        v-for="dot in myDesign.dots"
        :class="{
          ['is-success']: dot.status === 'Successful',
          ['is-failed']: dot.status === 'Failed'
        }"
        :id="`product-${product.id}-dot-${dot.id}`"
        :key="`product-${product.id}-dot-${dot.id}`"
        :ref="`product-${product.id}-dot-${dot.id}`"
      >
        <rect
          v-if="dot.shape === 'square'"
          :x="dot.cx - myDesign.dotRadius"
          :y="dot.cy - myDesign.dotRadius"
          :width="myDesign.dotRadius * 2"
          :height="myDesign.dotRadius * 2"
          @click="clickDot(dot, $event)"
          :stroke-width="
            isTemplate || (dot.status && (showGuides || !product.hideStatus))
              ? 3
              : 1
          "
          :stroke="
            isTemplate && !dot.status
              ? '#000'
              : !dot.status || (!showGuides && product.hideStatus)
              ? '#aaa'
              : dot.status === 'Successful'
              ? 'green'
              : 'red'
          "
          :fill="
            showFinished
              ? leanParams.availColors[dot.color].colorHex
              : 'rgba(0,0,0,0)'
          "
        />
        <circle
          v-if="dot.shape === 'circle'"
          :cx="dot.cx"
          :cy="dot.cy"
          :r="myDesign.dotRadius"
          @click="clickDot(dot, $event)"
          :stroke-width="
            isTemplate || (dot.status && (showGuides || !product.hideStatus))
              ? 3
              : 1
          "
          :stroke="
            isTemplate && !dot.status
              ? '#000'
              : !dot.status || (!showGuides && product.hideStatus)
              ? '#aaa'
              : dot.status === 'Successful'
              ? 'green'
              : 'red'
          "
          :fill="
            showFinished
              ? leanParams.availColors[dot.color].colorHex
              : 'rgba(0,0,0,0)'
          "
        />
        <line
          v-if="(!product.hideStatus && dot.status) || showGuides"
          :x1="dot.cx"
          :y1="dot.cy - product.design.targetRadius / 2"
          :x2="dot.cx"
          :y2="dot.cy + product.design.targetRadius / 2"
          stroke="#888"
        />
        <line
          v-if="(!product.hideStatus && dot.status) || showGuides"
          :x1="dot.cx - product.design.targetRadius / 2"
          :y1="dot.cy"
          :x2="dot.cx + product.design.targetRadius / 2"
          :y2="dot.cy"
          stroke="#888"
        />
        <circle
          v-if="(!product.hideStatus && dot.status) || showGuides"
          :cx="dot.cx"
          :cy="dot.cy"
          :r="product.design.targetRadius"
          stroke="#888"
          fill="rgba(0,0,0,0)"
          @click="clickDot(dot, $event)"
        />
      </g>
    </svg>
  </div>
</template>

<script>
// import anime from "animejs/lib/anime.es.js";
import { mapGetters } from "vuex";
// import tinycolor from "tinycolor2";

export default {
  name: "DotsSheetFigure",
  props: {
    product: Object,
    faciParams: Object,
    showGuides: Boolean,
    showFinished: Boolean,
    isDisabled: Boolean,
    isTemplate: Boolean
  },
  computed: {
    ...mapGetters(["leanParams"]),
    myDesign() {
      let retObj = { dots: [], shape: this.product.design.shape };
      let numCols = this.product.design.columns.length || 1;
      retObj.labels = this.product.design.columns.map((col, ind) => {
        return {
          text: col.label,
          y: 320 - 15,
          x: 20 + ((ind + 1) * 440) / numCols - 440 / (2 * numCols)
        };
      });
      let maxLines = 0;
      this.product.operations.forEach(ope => {
        if (
          ope.params &&
          ope.params.position &&
          ope.params.position[1] + 1 > maxLines
        ) {
          maxLines = ope.params.position[1] + 1;
        }
      });
      retObj.dotRadius = (0.4 / maxLines) * 260;
      this.product.operations.forEach(op => {
        if (op.ope === "painting") {
          retObj.dots.push({
            id: op.id,
            shape: op.params.shape,
            status: op.status ? op.status : null,
            cx:
              20 +
              ((op.params.position[0] + 1) * 440) / numCols -
              440 / (2 * numCols),
            cy:
              280 -
              10 +
              260 / (2 * maxLines) -
              (260 / maxLines) * (op.params.position[1] + 1),
            color: op.params.color
          });
        }
      });
      return retObj;
    }
  },
  mounted() {
    this.mySvgPoint = this.$el.querySelector("svg").createSVGPoint();
  },
  methods: {
    clickDot(dot, event) {
      if (this.isDisabled || this.isTemplate) {
        return;
      }
      this.mySvgPoint.x = event.clientX;
      this.mySvgPoint.y = event.clientY;
      let cursorpt = this.mySvgPoint.matrixTransform(
        this.$el
          .querySelector("svg")
          .getScreenCTM()
          .inverse()
      );
      let dist = Math.sqrt(
        (dot.cx - cursorpt.x) * (dot.cx - cursorpt.x) +
          (dot.cy - cursorpt.y) * (dot.cy - cursorpt.y)
      );
      // CASE 1 : PLACING A STAMP
      if (!dot.status && this.faciParams.color && this.faciParams.shape) {
        this.$emit(
          "addtrace",
          {
            id: `manudot-${cursorpt.x}-${cursorpt.y}`,
            opeId: dot.id,
            cx: cursorpt.x,
            cy: cursorpt.y,
            color: this.faciParams.color,
            shape: this.faciParams.shape
          },
          true
        );
        if (
          this.faciParams.color === dot.color &&
          this.faciParams.shape === dot.shape &&
          dist <= this.product.design.targetRadius
        ) {
          return this.$emit("applyopestatus", {
            status: "Successful",
            opeId: dot.id
          });
        } else {
          return this.$emit("applyopestatus", {
            status: "Failed",
            opeId: dot.id
          });
        }
      }
      // CASE 2 : REMOVING A STAMP
      if (dot.status && this.faciParams.stampRemove) {
        this.$emit("removetrace", {
          opeId: dot.id
        });
        return this.$emit("applyopestatus", { status: null, opeId: dot.id });
      }
    }
  },
  data() {
    return {
      mySvgPoint: null,
      manuDots: []
    };
  }
};
</script>

<style lang="scss">
.dots-sheet-figure-wrapper {
  cursor: crosshair;
  &.is-template {
    cursor: pointer;
  }
  &.is-disab {
    opacity: 1;
    cursor: not-allowed;
  }
}
</style>
